import {FormEvent, useState} from "react";
import axios from "axios";

export function Auth() {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        axios.post('/solver/auth', {
            username, password
        }).then((response) => {
            window.location.href = '/requests'
        }).catch(reason => alert('Wrong password'))
    }

    return <form onSubmit={onSubmit}>
        <div className="mb-3">
            <label className="form-label">Username</label>
            <input required={true} value={username} onChange={e => setUsername(e.target.value)} type="text"
                   className="form-control"/>

        </div>
        <div className="mb-3">
            <label className="form-label">Password</label>
            <input required={true} value={password} onChange={e => setPassword(e.target.value)} type="password"
                   className="form-control"/>
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
    </form>
}