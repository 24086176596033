import {Location, Task} from "./types/solver";
import _ from "lodash";
import {MapboxEvent} from "react-map-gl/dist/esm/types";
import {Anchor, Marker} from "react-map-gl";
import cx from "classnames";
import {defaultMarkerStyle, PopupInfo} from "./App";
import {formatDate} from "./utils";

type TaskMarkerProps = {
    tasks: Task[];
    droppedTasks: string[];
    setPopupInfo: (arg0: PopupInfo) => void;
    selectedMaker: {location: Location};
}

export function TaskMarker(props: TaskMarkerProps) {
    const {tasks, droppedTasks, setPopupInfo, selectedMaker} = props;

    return <>{_.chain(tasks).groupBy(t => t.taskType).map(taskByType => {
        const dropped = taskByType.filter(t => droppedTasks.includes(t.id)).length;
        const count = tasks.length - dropped;
        const task = taskByType[0];
        const countOrNull = count > 1 ? <span style={{color: 'green', fontSize: '20px'}}>{count}</span> : null;
        const {latitude, longitude} = task.location;

        const droppedOrNUll = dropped > 1 ? <span style={{color: 'red', fontSize: '20px'}}>{dropped}</span> : null;
        const onMarkerClick = (e: MapboxEvent<MouseEvent>) => {
            e.originalEvent.stopPropagation();
            const content = <table className="table">
                <thead>
                <tr>
                    <th>task id</th>
                    <th>type</th>
                    <th>dropped</th>
                    <th>Time window start</th>
                    <th>Time window end</th>
                    <th>duration</th>
                </tr>
                </thead>
                <tbody>
                {taskByType.map(t => <tr key={t.id}>
                        <td>{t.id}</td>
                        <td>{t.taskType}</td>
                        <td>{droppedTasks.includes(t.id) ? 'Yes' : 'No'}</td>
                        <td>{formatDate(t.timeWindows[0].start)}</td>
                        <td>{formatDate(t.timeWindows[0].end)}</td>
                        <td>{t.duration}</td>
                    </tr>
                )}
                </tbody>
            </table>
            setPopupInfo({
                latitude, longitude, content
            });
        }
        const anchor: Anchor = task.taskType.toLowerCase() === 'delivery' ? 'bottom' : 'top';
        const color = task.taskType.toLowerCase() === 'delivery' ? '#99d594' : '#fc8d59';
        const onlyDropped = count === 0;
        const isSelected = selectedMaker ? task.location.id === selectedMaker.location.id : false;
        return <Marker key={task.id}
                       onClick={onMarkerClick}
                       longitude={longitude}
                       latitude={latitude}
                       anchor={anchor}>
            {countOrNull}
            <i style={{
                ...defaultMarkerStyle,
                color: onlyDropped ? 'red' : color,
            ...(isSelected ? {fontSize: '50px', color: '#800080'} : {})
            }}
               className={cx('fa-regular', {
                   'fa-circle-down': task.taskType.toLowerCase() === 'delivery',
                   'fa-circle-up': task.taskType.toLowerCase() === 'pickup',
               })}
            ></i>
            {droppedOrNUll}
        </Marker>

    }).value()}</>;
}