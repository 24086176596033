import {Link, Outlet} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";

type RequestInfo = {
    request_id: string;
    created_at: string;
    date_done: string;
    duration: number;
    status: string;
    company_id: number;
    planned_routes_count: number;
    tasks_count: number;
    vehicles_count: number;
}

export function Requests() {
    // requests
    const [requests, setRequests] = useState<RequestInfo[]>([]);

    const [pageOffset, setPageOffset] = useState(0);
    const pageCount = 10;

      
    const handlePageChange = (event: {selected: number}) => {
        console.log(event);
        // TODO Only change displayed selected page
        // when its content is loaded in useEffect.
        setPageOffset(event.selected);
      };
        
    useEffect(() => {
        axios.get<RequestInfo[]>(`/solver/requests`, { params: { page_num: pageOffset }})
            .then((response) => {
                setRequests(response.data);
            });
    }, [pageOffset])

    for (var r of requests){
        if (r.date_done != null) {
            r.duration = (new Date(r.date_done).valueOf() - new Date(r.created_at).valueOf())/1000
            r.duration = Math.round(r.duration * 10) / 10
        }
    }

    return <>
        <table className="table table-hover">
            <thead>
            <tr>
                <th>Request id</th>
                <th>Requested at</th>
                <th>Completed at</th>
                <th>Duration(s)</th>
                <th>Status</th>
                <th>Company id</th>
                <th>Tasks count</th>
                <th>Vehicles count</th>
                <th>Planned routes</th>
            </tr>
            </thead>

            <tbody>
            {
                requests.map(r =>
                    <tr key={r.request_id}>
                        <td><Link to={`/solver/${r.request_id}`}>{r.request_id}</Link></td>
                        <td>{r.created_at.substring(0,19)}</td>
                        <td>{r.date_done ? r.date_done.substring(0,19) : r.date_done}</td>
                        <td>{r.duration}</td>
                        <td>{r.status}</td>
                        <td>{r.company_id}</td>
                        <td>{r.tasks_count}</td>
                        <td>{r.vehicles_count}</td>
                        <td>{r.planned_routes_count}</td>
                    </tr>)
            }
            </tbody>
        
        </table>
        <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={pageOffset}
              />
        <Outlet/>
    </>
}