import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
type ViewSwitcherProps = {
    isChecked: boolean;
    onViewListChange: (isChecked: boolean) => void;
    onViewModeChange: (viewMode: ViewMode) => void;
};
export const ViewSwitcher: React.FunctionComponent<ViewSwitcherProps> = ({
                                                               onViewModeChange,
                                                               onViewListChange,
                                                               isChecked,
                                                           }) => {
    return (
        <div className="btn-group">
            <button
                className="btn btn-primary"
                onClick={() => onViewModeChange(ViewMode.Hour)}
            >
                Hour
            </button>
            <button
                className="btn btn-primary"
                onClick={() => onViewModeChange(ViewMode.QuarterDay)}
            >
                Quarter of Day
            </button>
            <button
                className="btn btn-primary"
                onClick={() => onViewModeChange(ViewMode.HalfDay)}
            >
                Half of Day
            </button>
            <button className="btn btn-primary" onClick={() => onViewModeChange(ViewMode.Day)}>
                Day
            </button>
            <button
                className="btn btn-primary"
                onClick={() => onViewModeChange(ViewMode.Week)}
            >
                Week
            </button>
            <button
                className="btn btn-primary"
                onClick={() => onViewModeChange(ViewMode.Month)}
            >
                Month
            </button>

            {/*<div className="Switch">*/}
            {/*    <label className="Switch_Toggle">*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            defaultChecked={isChecked}*/}
            {/*            onClick={() => onViewListChange(!isChecked)}*/}
            {/*        />*/}
            {/*        <span className="Slider" />*/}
            {/*    </label>*/}
            {/*    Show Task List*/}
            {/*</div>*/}
        </div>
    );
};