import { Vehicle, VehicleType } from "./types/solver";
import { MapboxEvent } from "react-map-gl/dist/esm/types";
import { Marker } from "react-map-gl";
import { defaultMarkerStyle, PopupInfo } from "./App";
import { formatDate } from "./utils";

type VehicleMarkerProps = {
    vehicles: Vehicle[];
    vehicleTypes: VehicleType[];
    setPopupInfo: (arg0: PopupInfo) => void;
}

export function VehicleMarker(props: VehicleMarkerProps) {
    const { setPopupInfo, vehicles } = props;
    const vehicle = vehicles[0];
    const count = vehicles.length;

    const countOrNull = count > 1 ? count : null;
    const { latitude, longitude } = vehicle.startLocation;

    const onMarkerClick = (e: MapboxEvent<MouseEvent>) => {
        const content = <table className="table">
            <thead>
                <th>id</th>
                <th>tw start</th>
                <th>tw end</th>
            </thead>
            <tbody>
                {
                    vehicles.map(v => {
                        return <tr key={v.id}>
                            <td>{v.id}</td>
                            <td>{formatDate(v.timeSchedule.start)}</td>
                            <td>{formatDate(v.timeSchedule.end)}</td>
                        </tr>
                    })
                }
            </tbody>
        </table>

        e.originalEvent.stopPropagation();
        setPopupInfo({
            latitude, longitude, content
        });
    }

    return <Marker
        onClick={onMarkerClick}
        longitude={longitude}
        latitude={latitude}
        offset={[0, -10]}
        anchor="center">
        <i style={{ ...defaultMarkerStyle, color: 'blue' }} className="fa-solid fa-truck-pickup">{countOrNull}</i>
    </Marker>
}