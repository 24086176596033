import {useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {SolverRequest} from "./types/solver";
import axios from "axios";
import "gantt-task-react/dist/index.css";
import {Gantt, Task, ViewMode} from 'gantt-task-react';
import {ViewSwitcher} from "./view-switcher";
import _ from "lodash";


export function TimelineVisualisation() {
    const {requestId} = useParams();
    const [request, setRequest] = useState<SolverRequest>({
        tasks: [],
        vehicles: [],
        vehicleTypes: []
    } as unknown as SolverRequest);

    const [viewDate, setViewDate] = useState(new Date());


    const [tasks, setTasks] = useState<Task[]>([]);


    useMemo(() => {
        const newVehicles: Task[] = [];
        const newTasks = [] as Task[];

        request.vehicles.forEach(v => {
            const schedules = v.timeSchedules || [v.timeSchedule];
            schedules.forEach(ts => {
                newVehicles.push({
                    start: new Date(ts['start']),
                    end: new Date(ts['end']),
                    name: `Vehicle #${v['id']}`,
                    id: `vehicle${v['id']}`,
                    type: "project",
                    progress: 0,
                    isDisabled: true,
                })

            })
        })
        _.sortBy(request.tasks, t => t.id).forEach(task => {

            task.timeWindows.forEach(tw => {
                newTasks.push({
                    start: new Date(tw['start']),
                    end: new Date(tw['end']),
                    name: `${task['taskType']} (#${task['id']})`,
                    id: task['id'],
                    type: "task",
                    progress: 0,
                    isDisabled: true,
                    dependencies: [task.precedenceTaskId]
                })
            })

        })
        if (newTasks.length) {
            setTasks(newVehicles.concat(newTasks));
            const minDate = _.chain(newTasks).map(t => t.start).min().value();
            setViewDate(minDate);
        }

    }, [request.tasks, request.vehicles]);
    const [isChecked, setIsChecked] = useState(true);


    const [view, setView] = useState<ViewMode>(ViewMode.Hour);

    let columnWidth = 65;
    if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }


    useEffect(() => {
        axios.get<SolverRequest>(`/solver/request/${requestId}`)
            .then((response) => {
                setRequest(response.data);
            });
    }, [requestId]);


    return <>
        <ViewSwitcher
            onViewModeChange={viewMode => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
        />
        {tasks.length ? <Gantt
            tasks={tasks}
            columnWidth={columnWidth}
            viewMode={view}
            viewDate={viewDate}
        /> : null}
    </>

}