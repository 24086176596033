import {DateTime} from "luxon";
import axios from "axios";

export function formatDate(datetime: string) {
    const date = DateTime.fromISO(datetime);
    return date.toLocaleString(DateTime.DATETIME_SHORT)
}


// Add a response interceptor
axios.interceptors.response.use(response => response,  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
        window.location.replace('/auth');
    }
    return Promise.reject(error);
});