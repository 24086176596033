import {MouseEvent, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Capacity, SolverRequest} from "./types/solver";
import {SolverSolution} from "./types/solverSolution";
import axios from "axios";
import _ from "lodash";
import {TaskMarker} from "./TaskMarker";
import {VehicleMarker} from "./VehicleMarker";
import chroma from "chroma-js";
import poly from "@liberty-rider/flexpolyline";
import GeoJSON from "geojson";
import Map, {Layer, LineLayer, Popup, Source} from "react-map-gl";
import {DateTime, Duration} from "luxon";
import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {PopupInfo} from "./App";
import './index.css';

const TOKEN = 'pk.eyJ1IjoiYWludXJ5b2plZSIsImEiOiJja3R0Z3k0enowODhmMnBtbnMyc2xob2IzIn0.ZU5x_a4SdqDNaik6vycagw';


function isPickupOrDelivery(taskType: string) {
    return ['pickup', 'delivery'].includes(taskType.toLowerCase());
}

function ParseDate(date: string) {
    let dt = DateTime.fromISO(date);
    if (!dt.isValid) {
        dt = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ssZZ');
    }
    return dt;
}

export function MapVisualisation() {
    const [selectedMaker, setSelectedMaker] = useState<any | null>(null);
    const {requestId} = useParams();
    const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);

    const [selectedVehicle, setSelectedVehicle] = useState<number | null>(-999);

    const [viewState, setViewState] = useState({
        longitude: 121.0416799,
        latitude: 14.7222187,
        zoom: 10
    });

    const [request, setRequest] = useState<SolverRequest>({
        tasks: [],
        vehicles: [],
        vehicleTypes: []
    } as unknown as SolverRequest);


    const [solution, setSolution] = useState<SolverSolution>({
        routes: [],
        droppedTasks: []
    } as unknown as SolverSolution);

    useEffect(() => {
        axios.get<SolverRequest>(`/solver/request/${requestId}`)
            .then((response) => {
                if (response.data !== null) {
                    setRequest(response.data);
                }
            });

        axios.get<SolverSolution>(`/solver/result/${requestId}`)
            .then((response) => {
                if (response.data !== null) {
                    setSolution(response.data);
                }
            })
    }, [requestId]);


    useEffect(() => {
        if (request.vehicles.length > 0 || request.tasks.length > 0) {
            const vehicleStartLocations = request.vehicles.map(v => v.startLocation).filter(v => v);
            const locations = _.chain(request.tasks)
                .map(t => t.location).concat(vehicleStartLocations);


            const latitude = locations.map(l => l.latitude).mean().value();
            const longitude = locations.map(l => l.longitude).mean().value();
            setViewState({
                latitude, longitude, zoom: viewState.zoom
            });
        }
    }, [request.tasks, request.vehicles, viewState.zoom]);

    const markers = useMemo(() => {
        return request.tasks.length > 0 && _.chain(request.tasks).groupBy(t => {
            return t.location.latitude.toString() + t.location.longitude.toString()
        }).map(taskGroup => {
            return <TaskMarker key={taskGroup[0].id}
                               setPopupInfo={setPopupInfo}
                               droppedTasks={solution.droppedTasks}
                               tasks={taskGroup}
                               selectedMaker={selectedMaker}
            />
        }).value()
    }, [request.tasks, solution.droppedTasks, selectedMaker]);

    const vehicles = useMemo(() => {
        return request.vehicles.length > 0 && _.chain(request.vehicles)
            .groupBy(v => v.startLocation.latitude.toString() + v.startLocation.longitude.toString())
            .map(vehiclesGroup => {
                    return <VehicleMarker
                        key={vehiclesGroup[0].id}
                        setPopupInfo={setPopupInfo}
                        vehicles={vehiclesGroup}
                        vehicleTypes={request.vehicleTypes}
                    />
                }
            ).value()
    }, [request.vehicles, request.vehicleTypes]);

    const routes = useMemo(() => {
        //@ts-ignore
        const scale = chroma.bezier(['Red', 'Orange',	'Yellow',	'Green',	'Cyan',	'Blue',	'Violet']).scale().colors(solution.routes.length);

        return _.chain(solution.routes)

            .map(route => {
                    return {
                        route,
                        geojson: {
                            type: 'FeatureCollection',
                            features: route.directions.map(d => {
                                let polyline = [];
                                if (d.encodedPolyline) {
                                    polyline = poly.decode(d.encodedPolyline)
                                        .polyline.map((([c1, c2]) => [c2, c1]));
                                } else {
                                    polyline = d.polyline;
                                }

                                return {
                                    'type': 'Feature',
                                    'properties': {},
                                    'geometry': {
                                        type: 'LineString', coordinates: polyline

                                    }
                                };
                            }),
                        } as GeoJSON.FeatureCollection<GeoJSON.LineString>
                    };
                }
            ).map(({route, geojson}, idx) => {
                const sourceId = route.assignee.id.toString();
                const showLayer = selectedVehicle === null ? true : route.assignee.id === selectedVehicle ? true : false;
                const layerStyle = {
                    type: 'line',
                    interactive: true,
                    paint: {
                        'line-width': 2,
                        'line-color': scale[idx]
                    },
                    layout: {
                        visibility: showLayer ? 'visible' : 'none'
                    }


                } as LineLayer;
                return <Source key={sourceId} type="geojson" data={geojson}>
                    <Layer {...layerStyle} />
                </Source>

            }).value();
    }, [solution.routes, selectedVehicle])


    const stats = useMemo(() => {
        const solutionPlannedTasks = solution.routes.map(r => {
            return r.tour.filter(ti => {
                return isPickupOrDelivery(ti.taskType);
            })
        }).reduce((accumulator, currentValue) => {
            return accumulator + currentValue.length;
        }, 0)
        const rows = solution.routes.map(r => {
            const onRouteClick = (e: MouseEvent) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedVehicle(r.assignee.id)
            }

            // @ts-ignore
            const assignedVehicle = request.vehicles.find(v => parseInt(v.id, 10) === r.assignee.id);
            const vehicleType = assignedVehicle && request.vehicleTypes.find(_vt => _vt.id === assignedVehicle.vehicleTypeId);
            const assignedTasks = r.tour.filter(ti => {
                return isPickupOrDelivery(ti.taskType);
            });
  
            const duration = r.tour.map(t => t.duration).reduce((a, b) => a + b, 0);
            const distance = r.directions.map(t => t.distance).reduce((a, b) => a + b, 0);           
            const capacity  = (vehicleType?.capacity ?? {}) as Capacity;

            return <tr key={r.assignee.id}>
                <td><button type="button" className="btn btn-link p-0" onClick={onRouteClick}>{r.assignee.id}</button></td>
                <td>{assignedTasks.length}</td>
                <td>{capacity.unit}</td>
                <td>{capacity.volume}</td>
                <td>{capacity.weight}</td>
                <td>{Duration.fromObject({seconds: duration}).normalize().toFormat('hh:mm:ss')}</td>
                <td>{(distance / 1000).toLocaleString()}</td>
            </tr>
        })
        let chart = null;
        if (selectedVehicle != null) {
            const selectedRoute = solution.routes.find(r => r.assignee.id === selectedVehicle);
            if (selectedRoute) {
                const chartData = selectedRoute.tour.map(ti => {
                    let dt = ParseDate(ti.arrivalEarliest);
                    return {
                        time: dt.toLocaleString(DateTime.DATETIME_SHORT),
                        location: ti.location,
                        ...ti.loads
                    }
                })
                const onClick = (arg1: any) => {
                    setSelectedMaker(arg1.activePayload[0].payload);
                }


                chart = <>
                    <button className="btn btn-primary" onClick={() => setSelectedVehicle(null)}>Hide</button>
                    <LineChart
                        width={500}
                        height={200}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        onClick={onClick}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis
                            dataKey="time"
                        />
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="step" dataKey="weight" stroke="#e6194B"/>
                        <Line type="step" dataKey="volume" stroke="#ffe119"/>
                        <Line type="step" dataKey="unit" stroke="#4363d8"/>
                    </LineChart>
                </>
            }

        }


        return <div className="control-panel">
            <table className="table table-success">
                <thead>
                <tr>
                    <th>Request tasks</th>
                    <th>Request drivers</th>
                    <th>Solution droppedTasks</th>
                    <th>Solution plannedTasks</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{request.tasks.length}</td>
                    <td>{request.vehicles.length}</td>
                    <td>{solution.droppedTasks.length}</td>
                    <td>{solutionPlannedTasks}</td>
                </tr>
                </tbody>
            </table>

            <div className="table-wrapper-scroll-y my-custom-scrollbar">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>vehicleId</th>
                        <th>assignedTasks</th>
                        <th>capacity (u)</th>
                        <th>capacity (v)</th>
                        <th>capacity (w)</th>
                        <th>duration(h:m:s)</th>
                        <th>distance(km)</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </div>
            {chart}


        </div>
    }, [request.tasks, request.vehicles, request.vehicleTypes, solution.droppedTasks, solution.routes, selectedVehicle]);
    return <Map
        mapboxAccessToken={TOKEN}
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        style={{width: '100vw', height: '100vh'}}
        mapStyle="mapbox://styles/mapbox/light-v10"

    >
        {markers}
        {vehicles}
        {routes}
        {stats}

        {popupInfo && (
            <Popup
                anchor="top"
                longitude={Number(popupInfo.longitude)}
                latitude={Number(popupInfo.latitude)}
                onClose={() => setPopupInfo(null)}
                maxWidth={'none'}
            >
                <div>
                    {popupInfo.content}
                </div>
            </Popup>)}
    </Map>;
}