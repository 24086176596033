import {Link, Outlet, Route, Routes,} from "react-router-dom";

import {Requests} from "./Requests";
import {MapVisualisation} from "./MapVisualisation";
import {TimelineVisualisation} from "./TimelineVisualisation";
import {Auth} from "./Auth";

export const defaultMarkerStyle = {
    fontSize: '20px',
    padding: '5px',
    cursor: 'pointer'
}

export type PopupInfo = {
    longitude: number;
    latitude: number;
    content: any;
}


function Layout() {
    return (
        <>
            <nav>
                <h2><Link to="/requests" >Solver statistics</Link></h2>
            </nav>
            <div className="content">
                <Outlet/>
            </div>
            <div>
                <a href="https://yojee.com" target='_blank'>Copyright 2023</a> by Yojee. All Rights Reserved.
            </div>
        </>
    );
}


function Visualise() {
    return <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link" to="map">Map</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="timeline">Timeline</Link>
                </li>
                </ul>
            </div>
        </nav>
        <Outlet/>
    </>
}


export default function App() {
    return (
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route path="requests" element={<Requests/>}/>
                    <Route path="/solver/:requestId" element={<Visualise/>}>
                        <Route path="map" element={<MapVisualisation/>}/>
                        <Route path="timeline" element={<TimelineVisualisation/>}/>
                        <Route index element={<MapVisualisation/>}/>
                    </Route>
                </Route>
                <Route path="auth" element={<Auth/>}/>
            </Routes>
        );

}